import React, { FC, ReactNode, useState } from 'react';
import { Icon, Icons } from './Icon';
import { Color, IconCircle } from './IconCircle';
import { cx } from 'utils/classnames';
import DOMPurify from 'dompurify';
import { sanitizeOptions } from 'utils/sanitizeOptions';

export const BigRadioBox: FC<{
  icon?: Icons;
  iconColor?: string;
  iconBgColor?: string;
  title: string;
  description: string;
  span?: number;
  selected?: boolean;
  disabled?: boolean;
  onClick: () => void;
}> = ({
  icon,
  title,
  description,
  span = 1,
  onClick,
  selected = false,
  disabled = false,
}) => {
  return (
    <button
      type="button"
      data-id={title}
      className={cx(
        'group border rounded-lg p-8 col-span-${span} flex flex-col justify-between h-full ',
        selected && 'text-blue-700 border-blue-700',
        disabled
          ? 'cursor-not-allowed'
          : 'cursor-pointer hover:text-blue-700 hover:border-blue-700 focus:outline-none focus:text-blue-700 focus:border-blue-700',
      )}
      onClick={disabled ? null : onClick}
    >
      <div>
        {icon && (
          <IconCircle
            name={icon}
            color="lightgray"
            className={cx(
              'mb-8 ',
              selected && 'text-blue-700 bg-blue-000',
              !disabled &&
                'group-hover:text-blue-700 group-hover:bg-blue-000 group-focus:text-blue-700 group-focus:bg-blue-000',
            )}
            circleSize={16}
            iconSize={8}
          />
        )}
        <div className="text-xl mb-6 text-left">{title}</div>
        <div className="text-gray-500 h-30 text-left">{description}</div>
      </div>
      <Icon
        name="arrow_forward"
        size={6}
        className={cx(
          'text-gray-500 ',
          selected && 'text-blue-700',
          !disabled && 'group-hover:text-blue-700 group-focus:text-blue-700',
        )}
      />
    </button>
  );
};

export const SmallRadioBox: FC<{
  icon: Icons;
  color: Color;
  title: string;
  valueLabel?: string;
  selected?: boolean;
  readOnly?: boolean;
  onClick: () => void;
}> = ({
  icon,
  color,
  title,
  valueLabel,
  readOnly,
  selected = false,
  onClick,
}) => {
  const buttonHoverClass = readOnly
    ? null
    : 'hover:text-blue-700 hover:border-blue-700';
  const buttonCursorClass = readOnly ? 'cursor-auto' : 'cursor-pointer';
  const iconHoverClass = readOnly ? null : 'group-hover:text-blue-700';
  const selectedClass = selected
    ? 'text-blue-700 border-blue-700 bg-blue-000'
    : '';

  return (
    <div className='flex'>
      <button
        data-id={title}
        type="button"
        className={`small-radio-box-value group ${buttonCursorClass} border rounded-lg p-5 flex flex-1  justify-between items-center w-full ${buttonHoverClass} focus:outline-none focus:text-blue-700 focus:border-blue-700 ${selectedClass}`}
        disabled={readOnly}
        onClick={onClick}
      >
        <div className="flex space-x-5">
          {icon && (
            <IconCircle
              name={icon}
              color={color}
              circleSize={10}
              iconSize={5}
			  className='flex-none'
            />
          )}
          <div className='flex items-start flex-col justify-center'>
            <div className="text-left">{title}</div>
            {valueLabel && (
              <div className='text-gray-600 text-left'>{valueLabel}</div>
            )}
          </div>
        </div>
        <Icon
          name="arrow_forward"
          size={6}
          className={`mr-2 flex-none text-gray-500 ${iconHoverClass} group-focus:text-blue-700 ${
            selected ? 'text-blue-700' : ''
          }`}
        />
      </button>
    </div>
  );
};

export const RadioBoxContainer: FC<{
  type: 'big' | 'small';
  name?: string;
  label?: string;
  right?: ReactNode;
  legend?: string;
  helptext?: string;
  required?: boolean;
  colSize?: number;
  rowSize?: number;
  className?: string;
  errorMessage?: string;
}> = ({
  children,
  name,
  type,
  label,
  right,
  legend,
  helptext,
  required,
  colSize,
  rowSize,
  className,
  errorMessage,
}) => {
  const [helptTextVisible, setHelptTextVisible] = useState(false);
  const helpTextHtml = {
    dangerouslySetInnerHTML: {
      __html: DOMPurify.sanitize(helptext, sanitizeOptions()),
    },
  };
  const legendHtml = {
    dangerouslySetInnerHTML: {
      __html: DOMPurify.sanitize(legend, sanitizeOptions()),
    },
  };

  if (type === 'big') {
    return (
      <div className={`w-full mb-6 p-2 ${className}`} data-id={`field-${name}`}>
        {label && (
          <div className="text-md text-gray-600 flex items-center">
            {label}
            {required && <span className="ml-1 text-red-700">*</span>}
            {helptext && (
              <span
                className="cursor-pointer ml-2"
                onClick={() => {
                  setHelptTextVisible(!helptTextVisible);
                }}
              >
                <Icon name="question" size={5} />
              </span>
            )}
            {right && <div className="leading-normal select-none">{right}</div>}
          </div>
        )}
        {legend && <div className="text-sm text-gray-500" {...legendHtml} />}
        {helptext && helptTextVisible && (
          <div className="text-gray-500" {...helpTextHtml} />
        )}
        <div className={`grid grid-cols-${colSize} mt-6 gap-4`}>{children}</div>
      </div>
    );
  }

  return (
    <div className={`w-full mb-6 p-2 ${className}`} data-id={`field-${name}`}>
      {label && (
        <div
          className={`text-md  flex items-center ${
            errorMessage ? 'text-red-700' : 'text-gray-600'
          }`}
        >
          <div className={'w-full flex flex-row justify-between '}>
            <div>
              {label}
              {required && <span className="ml-1 text-red-700">*</span>}
            </div>
            {right && <div className="leading-normal select-none">{right}</div>}
          </div>

          {helptext && (
            <span
              className="cursor-pointer ml-2"
              onClick={() => {
                setHelptTextVisible(!helptTextVisible);
              }}
            >
              <Icon name="question" size={5} />
            </span>
          )}
        </div>
      )}
      {legend && <div className="text-sm text-gray-500" {...legendHtml} />}
      {helptext && helptTextVisible && (
        <div className="text-gray-500" {...helpTextHtml} />
      )}
      <div
        className={`grid gap-4 mt-8 grid-cols-${colSize} grid-rows-${rowSize} ${
          errorMessage ? 'border-b border-red-700' : ''
        }`}
      >
        {children}
      </div>
      {errorMessage && (
        <p className="pt-2 text-sm text-red-700" role="alert"></p>
      )}
    </div>
  );
};
